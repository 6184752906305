@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Font';
  src: url('./assets/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

